import { combineReducers } from 'redux';

import { reducer as articles, initialState as articlesState } from './articles';
import {
  reducer as articleTypes,
  initialState as articleTypesState
} from './articleTypes';
import { reducer as auth, initialState as authState } from './auth';
import { reducer as contact, initialState as contactState } from './contact';
import { reducer as editor, initialState as editorState } from './editor';
import {
  reducer as privilege,
  initialState as privilegeState
} from './privilege';
import { reducer as role, initialState as roleState } from './role';
import { reducer as toast, initialState as toastState } from './toast';
import { reducer as user, initialState as userState } from './user';

export const initialState = {
  articles: articlesState,
  articleTypes: articleTypesState,
  auth: authState,
  contact: contactState,
  editor: editorState,
  privilege: privilegeState,
  role: roleState,
  toast: toastState,
  user: userState
};

export default combineReducers({
  articles,
  articleTypes,
  auth,
  contact,
  editor,
  privilege,
  role,
  toast,
  user
});
