import { buildActions } from 'utils';

export const types = buildActions('contact', [
  'SEND_CONTACT_MESSAGE',
  'SENDING_CONTACT_MESSAGE',
  'SENT_CONTACT_MESSAGE',
  'CONTACT_MESSAGE_FAILURE'
]);

const sendContactMessage = (details) => ({
  type: types.SEND_CONTACT_MESSAGE,
  details
});

const sendingContactMessage = (status) => ({
  type: types.SENDING_CONTACT_MESSAGE,
  status
});

const sentContactMessage = (status) => ({
  type: types.SENT_CONTACT_MESSAGE,
  status
});

const contactMessageFailure = (error) => ({
  type: types.CONTACT_MESSAGE_FAILURE,
  error
});

export const actions = {
  sendContactMessage,
  sendingContactMessage,
  sentContactMessage,
  contactMessageFailure
};

export const initialState = {
  error: {},
  sending: false,
  sent: false
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SENDING_CONTACT_MESSAGE:
      return {
        ...state,
        sending: action.status
      };
    case types.SENT_CONTACT_MESSAGE:
      return {
        ...state,
        sending: false,
        sent: action.status
      };
    case types.CONTACT_MESSAGE_FAILURE:
      return {
        ...state,
        sending: false,
        sent: false,
        error: action.error
      };
    default:
      return state;
  }
};
