import { lazy } from 'react';

export const imports = {
  AdminArticles: () =>
    import(/* webpackChunkName: "admin-articles" */ 'pages/Admin/Articles'),
  AdminArticleTypes: () =>
    import(
      /* webpackChunkName: "admin-article-types" */ 'pages/Admin/ArticleTypes'
    ),
  AdminPrivileges: () =>
    import(/* webpackChunkName: "admin-privileges" */ 'pages/Admin/Privileges'),
  AdminRoles: () =>
    import(/* webpackChunkName: "admin-roles" */ 'pages/Admin/Roles'),
  AdminUsers: () =>
    import(/* webpackChunkName: "admin-users" */ 'pages/Admin/Users'),
  Article: () => import(/* webpackChunkName: "article" */ 'pages/Article'),
  Articles: () => import(/* webpackChunkName: "articles" */ 'pages/Articles'),
  ArticleType: () =>
    import(/* webpackChunkName: "article-type" */ 'pages/ArticleType'),
  Contact: () => import(/* webpackChunkName: "contact" */ 'pages/Contact'),
  Home: () => import(/* webpackChunkName: "home" */ 'pages/Home'),
  Login: () => import(/* webpackChunkName: "login" */ 'pages/Login'),
  NotFound: () => import(/* webpackChunkName: "not-found" */ 'pages/NotFound'),
  Privacy: () => import(/* webpackChunkName: "privacy" */ 'pages/Privacy'),
  Register: () => import(/* webpackChunkName: "register" */ 'pages/Register'),
  User: () => import(/* webpackChunkName: "user" */ 'pages/User')
};

export const AdminArticles = lazy(imports.AdminArticles);
export const AdminArticleTypes = lazy(imports.AdminArticleTypes);
export const AdminPrivileges = lazy(imports.AdminPrivileges);
export const AdminRoles = lazy(imports.AdminRoles);
export const AdminUsers = lazy(imports.AdminUsers);
export const Article = lazy(imports.Article);
export const Articles = lazy(imports.Articles);
export const ArticleType = lazy(imports.ArticleType);
export const Contact = lazy(imports.Contact);
export const Home = lazy(imports.Home);
export const Login = lazy(imports.Login);
export const NotFound = lazy(imports.NotFound);
export const Privacy = lazy(imports.Privacy);
export const Register = lazy(imports.Register);
export const User = lazy(imports.User);
