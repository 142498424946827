import React, { useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ToastDrawer from 'components/ToastDrawer/ToastDrawer';
import {
  AdminArticles,
  AdminArticleTypes,
  AdminPrivileges,
  AdminRoles,
  AdminUsers,
  Article,
  Articles,
  ArticleType,
  Contact,
  Home,
  Login,
  NotFound,
  Privacy,
  Register,
  User
} from 'pages';
import SuspenseFallback from 'components/SuspenseFallback/SuspenseFallback';
import { actions as authActions } from 'redux/reducers/auth';

export function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.initApp());
  }, [dispatch]);

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Helmet
        defaultTitle={process.env.REACT_APP_HTML_TITLE}
        titleTemplate={`%s | ${process.env.REACT_APP_HTML_TITLE}`}
      />
      <ToastDrawer />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/admin/articles" component={AdminArticles} />
        <Route
          exact
          path="/admin/article-types"
          component={AdminArticleTypes}
        />
        <Route exact path="/admin/privileges" component={AdminPrivileges} />
        <Route exact path="/admin/roles" component={AdminRoles} />
        <Route exact path="/admin/users" component={AdminUsers} />
        <Route exact path="/articles" component={Articles} />
        <Route exact path="/articles/:articleType" component={ArticleType} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/user" component={User} />
        <Route exact path="/:articleType/:article" component={Article} />
        <Route exact path="/privacy" component={Privacy} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default withRouter(App);
