import { all, call, put, takeLatest } from 'redux-saga/effects';
import helper from 'utils/saga';
import request from 'utils/request';
import { actions, types } from 'redux/reducers/contact';

// snake_cased variables here come from RFC 6749
function* sendContactMessageWorker({
  details: { email, location, name, phone, message }
}) {
  try {
    yield put(actions.sendingContactMessage(true));

    const data = {
      email,
      group: 'bentec',
      location,
      name,
      phone,
      message
    };

    const endpoint = {
      url: `/mailer`,
      method: 'POST'
    };
    const result = yield call(request.execute, { endpoint, data });

    if (result.success) {
      yield put(actions.sendingContactMessage(false));
      yield put(actions.sentContactMessage(true));
      yield call(helper.toast, {
        title: 'Contact',
        message: `Message successfully sent! Thank you!`
      });
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to send message!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.contactMessageFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* sendContactMessageWatcher() {
  yield takeLatest(types.SEND_CONTACT_MESSAGE, sendContactMessageWorker);
}

export const workers = {
  sendContactMessageWorker
};

export const watchers = {
  sendContactMessageWatcher
};

export default function* saga() {
  yield all(Object.values(watchers).map((watcher) => watcher()));
}
