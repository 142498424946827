import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap';

export default function Footer() {
  return (
    <Container
      className="border-top border-light bg-secondary mt-2 pt-4 pb-4 text-left text-light text-align-middle"
      fluid
    >
      <Col>
        <span>
          &copy; 2019-2020 Bentec Consulting LLC. All rights reserved. |{' '}
          <Link to="/contact" alt="Contact Us" className="text-primary">
            Contact Us
          </Link>{' '}
          |{' '}
          <Link to="/privacy" className="text-primary">
            Privacy Policy
          </Link>
        </span>
      </Col>
    </Container>
  );
}
