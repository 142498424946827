import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { actions as authActions } from 'redux/reducers/auth';
import { getCurrentUser, isLoggedIn } from 'redux/selectors/auth';

export default function Header() {
  const dispatch = useDispatch();

  const loggedIn = useSelector(isLoggedIn);
  const user = useSelector(getCurrentUser);

  useEffect(() => {
    dispatch(authActions.requestCurrentUser());
  }, [dispatch]);

  const userIcon = () => {
    return <FontAwesomeIcon icon="user-circle" />;
  };

  const logoutUser = (values) => {
    dispatch(authActions.logoutUser());
  };

  return (
    <Fragment>
      <div className="navbar-underlay">
        <Navbar bg="light" variant="light" expand="lg">
          <div>
            <Navbar.Brand as={Link} to="/" className="font-weight-bolder">
              BenTec
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="sublogo">
              <FontAwesomeIcon
                icon="cog"
                color="#1a9122"
                className="sublogo--top"
                spin
              />{' '}
              <span className="sublogo-text--top">Consulting</span>
              <br />
              <FontAwesomeIcon
                icon="cog"
                color="#1a9122"
                className="sublogo--middle spin-reverse"
                spin
              />{' '}
              <span className="sublogo-text--middle">Solutions</span>
              <br />
              <FontAwesomeIcon
                icon="cog"
                color="#1a9122"
                className="sublogo--bottom"
                spin
              />{' '}
              <span className="sublogo-text--bottom">Maintenance</span>
            </div>
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto mr-auto">
              <h3>
                <Nav.Link as={NavLink} to="/">
                  Home
                </Nav.Link>
              </h3>
              <h3>
                <Nav.Link as={NavLink} to="/pages/services">
                  Services
                </Nav.Link>
              </h3>
              <h3>
                <Nav.Link as={NavLink} to="/articles/projects">
                  Projects
                </Nav.Link>
              </h3>
              <h3>
                <Nav.Link as={NavLink} to="/articles/blog">
                  Blog
                </Nav.Link>
              </h3>
              <h3>
                <Nav.Link as={NavLink} to="/pages/about">
                  About Us
                </Nav.Link>
              </h3>
              <h3>
                <Nav.Link as={NavLink} to="/contact">
                  Contact
                </Nav.Link>
              </h3>
            </Nav>
            <Nav>
              {user?.privileges?.ui_admin ? (
                <Dropdown className="mr-1">
                  <Dropdown.Toggle variant="secondary" id="admin-dropdown">
                    Administrator
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to="/admin/roles">
                      Roles
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/admin/privileges">
                      Privileges
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/admin/users">
                      Users
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={NavLink} to="/admin/article-types">
                      Article Types
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/admin/articles">
                      Articles
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}

              {loggedIn && user ? (
                <Dropdown alignRight>
                  <Dropdown.Toggle variant="primary" id="user-dropdown">
                    {userIcon()} {user.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to="/user">
                      Account
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as={NavLink}
                      to="/login"
                      onClick={() => logoutUser()}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Fragment>
                  <Nav.Link as={NavLink} to="/login">
                    Login
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/register">
                    Register
                  </Nav.Link>
                </Fragment>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </Fragment>
  );
}
