import { all, fork } from 'redux-saga/effects';

import articles from './articles';
import articleTypes from './articleTypes';
import auth from './auth';
import contact from './contact';
import editor from './editor';
import privilege from './privilege';
import role from './role';
import toast from './toast';
import user from './user';

export default function* saga() {
  yield all(
    [
      articles,
      articleTypes,
      auth,
      contact,
      editor,
      privilege,
      role,
      toast,
      user
    ].map(fork)
  );
}
